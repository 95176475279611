@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

.container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  /* height: 100vh; */
  width: 100vw;
}

.wrapper {
  width: 80%;
}
.infolog1--text{
  font-family: 'Source Sans Pro';
  color: #200A58;
  flex-direction: 'row';
  background-color: #eee;
  text-align: 'center';
}

.section{
  text-align: center;
}