.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    cursor: pointer;
    margin-top: 30px;
  }
  
  .pagination a {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #484474;
    color: #484474;
    margin: 10px;
  }
  
  .pagination__link {
    font-weight: bold;
  }
  
  .pagination__link--active a {
    color: #fff;
    background: #484474;
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }

  