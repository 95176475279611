* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
   
}
.mainBg{
    background-color: #FFFF;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;

    
   
}


  