@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

.container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
 
  width: 100vw;
  padding-bottom: 50px;
  
}

.wrapper {
  width: 80%;
 
}
.infolog1--text{
  font-family: 'Source Sans Pro';
  color: #200A58;
  flex-direction: 'row';
  background-color: "#eee";
  text-align: 'center';
}

.section{
  text-align: center;
}


.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}