.intro{
    margin: 0px 20px 0px 20px;
}

.buttonDesign{
    margin: 20px;
}

.csrIdea {
    text-align: center;
    font-size: 22px;
    font-weight: 700; color:#202020;
    text-transform: uppercase;
    word-spacing: 1px; letter-spacing:2px;
  }

  .spanText{
    margin-top: 10px;
    text-transform: none;
    font-size:16;
    font-weight: normal;
    font-style: italic; font-family: "Playfair Display","Bookman",serif;
    color:#999; letter-spacing:-0.005em; word-spacing:1px;
    letter-spacing:none;
    text-align: center;
  }

  .csrIdea::before{
    position: absolute;
    left: 0;
    bottom: 38px;
    width: 60px;
    height: 4px;
    content: "";
    left: 50%;
    margin-left: -30px;
    background-color: #dfdfdf;
  }

  .csrIdeaDiv{
    align-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .mainBg{
    padding: 10px;
    
}

.datePicker {
  width: 100%;
}