.tableFooter {
    background-color:#EFEFEF;
    padding: 8px 0px;
    width: 100%;
    font-weight: 500;
    text-align: left;
    font-size: 16px;
    color: #333333;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button {
    border: none;
    padding: 7px 14px;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 4px;
    margin-left: 4px;
  }
  
  .activeButton {
    color:#333333;
    background: #A8A8A8;
  }
  
  .inactiveButton {
    color: #2c3e50;
    background: #f9f9f9;
  }