.table-container {
  width: 100%;
  max-height: calc(100vh - 50px); 
  overflow-y: auto; 
  margin-bottom: 20px; /* Add some margin between the table and the footer */
}

.table {
  border-collapse: collapse;
  border: none;
  table-layout:fixed;  
  width: 100%;
  background-color: #FFFFFF;
  word-wrap:break-word;  
  border : 1px solid;
  border-collapse: collapse;
  overflow-y: scroll;
}

.tableRowHeader {
  background-color: transparent;
  transition: all 0.25s ease;
 
  
}

.tableHeader {
  background-color:  white;
  padding: 12px;
  font-weight: 600;
  text-align: left;
  font-size: 14px;
  color: #333333;
  font-family: 'Source Sans Pro', serif;  
  border : 1px solid;  
  border-collapse: collapse;
}

.tableHeader:first-child {
  
}

.tableHeader:last-child {
  
}

.tableRowItems {
  cursor: auto;
}

.tableRowItems:nth-child(odd) {
  background-color:#E8E8E8;
}

.tableCell {
  padding: 12px;
  font-size: 14px;
  color: #333333;
  font-family: 'Source Sans Pro', serif;
  
  border : 1px solid #a8a8a8;
  border-collapse: collapse;
}