@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
   
}
.mainBg{
    background-color: #FFFF;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;

    
   
}


  
img{
  width: 80px;
  padding-right: 10px;
}

.navbar {
  background: #fff;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 0.2px solid gray
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 100px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color:  #E31937;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  font-family: 'Helvetica Neue Light';
  display: flex;
  align-items: center;
}

.navbar-logo:hover{
  color: #E31937;
  text-decoration: none;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: end;
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #5236AB;
  text-decoration: none;

}

.nav-links {
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

/*.nav-links:hover {
  color: #f00946;

  transform: scale(1.2);
  transition: all 0.3s ease;
   
}
*/

.Logo--text{
  color: #E31937;
  padding: 10px;
 

}

.nav-links:hover{
  text-decoration: none;
}

.fa-bars {
  color: #E31937;
}

.menu-icon {
  display: none;
}

/*.nav-links:hover {
  color: #f00946;
  text-decoration: none;

  transform: scale(1.2);
  transition: all 0.3s ease;
}*/

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #5a5656;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    color: white;
    text-align: center;
    padding: 0.1rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #f00946;

    -webkit-transform: scale(1.2);

            transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
    padding: 10px;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #000;
    font-size: 2rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
}
.home__hero-section {
    color: #fff(0, 0, 0);
    padding: 10px 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 50px;
  }
  
  .home__hero-row {
    align-items: center;
  }
  
  .row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
  }
  
  .col {
    margin-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    flex: 1 1;
    max-width: 50%;
    flex-basis: 50%;
    font-family: 'Source Sans Pro', serif;
    
  }
  
  .home__hero-text-wrapper {
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
    
  }
  .paragraph{
    text-align: justify;
  }
  .top-line {
    color: #f00946;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  
  .heading {
    margin-bottom: 24px;
    line-height: 1.4em;
    font-weight: 400;
    color: #200A58;
    font-size: 38px;
    font-family: 'Source Sans Pro', serif;

    
  }
  
  .dark {
    color: #200A58;
    font-family: 'Source Sans Pro', serif;
    
  }
  
  .darkBg {
   
    background-color: #1c1c1c;
    color: #fff;
  }
  
  .darkBg * {
    color: #fff;
  }
  
  .home__hero-subtitle {
    max-width: 540px;
    margin-bottom: 10px;
    font-size: 17px;
    text-align: justify;
    line-height: 24px;
    color: white;

  }

  .home__hero-subtitle2 {
    max-width: 540px;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: justify;
    line-height: 24px;
    padding-left: 50px;
    
  }
  
  .home__hero-img-wrapper {   
 
    display: flex;
    justify-content: center;
  }
  
  .home__hero-img {   
   
    width: 50%;
   
    margin-top: 0;
    margin-right: 0;
     
    
    padding-right: 0;
    
    margin: 0.5em;
    padding: 0.5em;
   
  }
  .divider{
    border:1px solid gray;
    background-image: linear-gradient(#E31937,#5236AB);
    
    width:6px;
    
    margin-top: 12px;
    margin-bottom : 12px;
 
  }
  .divider2{
    text-align:center;
    margin-top:auto;
    margin-bottom:auto;
  }

 

  .right{
    height:50px;
    width: 50px;
    
  }
  .text {
    font-family: 'Source Sans Pro';
    font-size: large;
    
    
  }
  .corner{
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  img {
    border: 0;
    max-width: 100%;
    vertical-align:middle;
    
    
    display: inline-block;
    
  }
  
  @media screen and (max-width: 991px) {
    .container {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .home__hero-text-wrapper {
      padding-bottom: 65px;
    }
  
    .col {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .links{
    color: #270a9d;
  }


  .material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 100,
    'GRAD' 200,
    'opsz' 48;
    align-self: center;
    
  }
 

:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--mobile {
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }
  
  .btn--wide {
    padding: 12px 64px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover,
  .btn--mobile:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
  }
  
  .btn--wide:hover {
    color: #fff;
    background-color: #f00946;
    transition: all 0.2s ease-out;
  }
  
  .btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
  }
  
  .blue {
    background-color: #E31937;
    color: #fff;
    border: none;
  }

  .cgiColor {
    background-color: #E31937;
    color: #fff;
    border: none;
  }
  
  .red {
    background-color: #E31937;
    color: #fff;
    border: none;
    margin: 50;
  }
  
  .primary {
    background-color: #242424;
    color: #fff;
    border: none;
  }
  
  .primary:hover {
    background-color: #fff;
    color: #242424;
    border: none;
  }
  
  .green {
    background: #25ce4a;
    color: #fff;
    border: none;
  }
  
  .green:hover {
    background-color: #242424;
  }
.card {
  width: 33%;
  display: flex;
  margin: 3px;
  max-height: 25%;
  overflow: scroll;
  font-size: 12px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid gray;
  border-top: 7px solid gray;
}

.title {
  font-family: Source Sans Pro;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #200a58;
  line-height: 1.4em;
}

.text {
  font-family: Source Sans Pro;
  padding: 5px 10px 10px;
  font-size: 15px;
  font-weight: 300;
  color: #1c1c1c;
  line-height: 1.5em;
  vertical-align: middle;
  flex: 1 1;
}

.date {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 400;
  color: #555;
  display: flex;
  align-items: center;
  padding: 10px;
}

.time {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 400;
  color: #555;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-left: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.join-button {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #200a58;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.join-button:hover {
  background-color: #fff;
  color: #200a58;
  border: 1px solid #200a58;
}

.read-more {
    color: blue;
    text-decoration: underline;
  }
  

/* Footer.css */
.footer-container {
  background-color: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 10px;
  height: 40px;

}


  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  
  .footer-link-items > h2 {
    color: #000;
  }
  
  .footer-link-items a {
    color: #000;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #000;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo { /* This is the main styling for the footer text */
    color: #000;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  
  .website-rights {
    color: #000;
    margin-bottom: 16px;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }

  .social-logo:hover{
text-decoration: none;
color: #fff;
  }
.titleCsr{
    text-align: center;
    align-items: center;
    font-size:40px; 
   
    
    letter-spacing:1px;
    text-transform: uppercase;
    display: grid;
    grid-template-columns: 1fr -webkit-max-content 1fr;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 50px;
    font-family: 'Source Sans Pro';
    color: #200A58;
    flex-direction: 'row';
    background-color: "#eee";
    text-align: 'center';
}

.titleCsr::after, .titleCsr::before{
    content: " ";
    display: block;
    height: 5px;
}


.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    cursor: pointer;
    margin-top: 30px;
  }
  
  .pagination a {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #484474;
    color: #484474;
    margin: 10px;
  }
  
  .pagination__link {
    font-weight: bold;
  }
  
  .pagination__link--active a {
    color: #fff;
    background: #484474;
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }
  
.intro{
    margin: 0px 20px 0px 20px;
}

.buttonDesign{
    margin: 20px;
}

.csrIdea {
    text-align: center;
    font-size: 22px;
    font-weight: 700; color:#202020;
    text-transform: uppercase;
    word-spacing: 1px; letter-spacing:2px;
  }

  .spanText{
    margin-top: 10px;
    text-transform: none;
    font-size:16;
    font-weight: normal;
    font-style: italic; font-family: "Playfair Display","Bookman",serif;
    color:#999; letter-spacing:-0.005em; word-spacing:1px;
    letter-spacing:none;
    text-align: center;
  }

  .csrIdea::before{
    position: absolute;
    left: 0;
    bottom: 38px;
    width: 60px;
    height: 4px;
    content: "";
    left: 50%;
    margin-left: -30px;
    background-color: #dfdfdf;
  }

  .csrIdeaDiv{
    align-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .mainBg{
    padding: 10px;
    
}

.datePicker {
  width: 100%;
}
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    cursor: pointer;
    margin-top: 30px;
  }
  
  .pagination a {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #484474;
    color: #484474;
    margin: 10px;
  }
  
  .pagination__link {
    font-weight: bold;
  }
  
  .pagination__link--active a {
    color: #fff;
    background: #484474;
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }
  
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    cursor: pointer;
    margin-top: 30px;
  }
  
  .pagination a {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #484474;
    color: #484474;
    margin: 10px;
  }
  
  .pagination__link {
    font-weight: bold;
  }
  
  .pagination__link--active a {
    color: #fff;
    background: #484474;
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }

  
.Program_container__rx3WN {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  /* height: 100vh; */
  width: 100vw;
}

.Program_wrapper__R814E {
  width: 80%;
}
.Program_infolog1--text__2RYJA{
  font-family: 'Source Sans Pro';
  color: #200A58;
  flex-direction: 'row';
  background-color: #eee;
  text-align: 'center';
}

.Program_section__2EYjl{
  text-align: center;
}
.Table_table__2rfZR {
  border-collapse: collapse;
  border: none;
  table-layout:fixed;  
  width: 100%;
  background-color: #FFFFFF;
  word-wrap:break-word;  
  border : 1px solid;
  border-collapse: collapse;
  overflow-y: scroll;
}

.Table_tableRowHeader__1qNOw {
  background-color: transparent;
  transition: all 0.25s ease;
 
  
}

.Table_tableHeader__2kYTs {
  background-color:  white;
  padding: 12px;
  font-weight: 600;
  text-align: left;
  font-size: 14px;
  color: #333333;
  font-family: 'Source Sans Pro', serif;
  
  border : 1px solid;
  border-collapse: collapse;
}

.Table_tableHeader__2kYTs:first-child {
  
}

.Table_tableHeader__2kYTs:last-child {
  
}

.Table_tableRowItems__2Vp8q {
  cursor: auto;
}

.Table_tableRowItems__2Vp8q:nth-child(odd) {
  background-color:#E8E8E8;
}

.Table_tableCell__3exaF {
  padding: 12px;
  font-size: 14px;
  color: #333333;
  font-family: 'Source Sans Pro', serif;
  
  border : 1px solid #a8a8a8;
  border-collapse: collapse;
}
.TableFooter_tableFooter__3t5Rv {
  background-color:#EFEFEF;
  padding: 8px 0px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  color: #333333;

  display: flex;
  align-items: center;
  justify-content: center;
}

.TableFooter_button__3pE63 {
  border: none;
  padding: 7px 14px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}

.TableFooter_activeButton__3dd6g {
  color:#333333;
  background: #A8A8A8;
}

.TableFooter_inactiveButton__k2pVj {
  color: #2c3e50;
  background: #f9f9f9;
}
.Program_container__co8xf {
  display: flex;
  justify-content: center;
  margin-top: 30px;
 
  width: 100vw;
  padding-bottom: 50px;
  
}

.Program_wrapper__37FmV {
  width: 80%;
 
}
.Program_infolog1--text__36WKr{
  font-family: 'Source Sans Pro';
  color: #200A58;
  flex-direction: 'row';
  background-color: "#eee";
  text-align: 'center';
}

.Program_section__2J_s3{
  text-align: center;
}


.Program_material-icons__CEJ2d {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
.Table_table__1TJE4 {
    border-collapse: collapse;  
    
    width: 100%;
    table-layout:fixed;
    word-wrap:break-word;
    
    background-color: #FFFFFF;
    border : 1px solid;
    border-collapse: collapse;  
    overflow-y: scroll; 
  
  
  }
  
  .Table_tableRowHeader__JFb-r {
    background-color: transparent;
    transition: all 0.25s ease;
     
    
  }
  
  .Table_tableHeader__2BspI {
    background-color:  white;
    padding: 12px;
    font-weight: 600;
    text-align: justify;
    font-size: 14px;
    color: #333333;
    border : 1px solid;
    border-collapse: collapse;
    white-space: nowrap;
  }
  
  .Table_tableHeader__2BspI:first-child {
    
  }
  
  .Table_tableHeader__2BspI:last-child {
    
  }
  
  .Table_tableRowItems__3PWcM {
    cursor: auto;
  }
  
  .Table_tableRowItems__3PWcM:nth-child(odd) {
    background-color:#E8E8E8;
  }
  
  .Table_tableCell__30GEh {
    padding: 12px;
    font-size: 14px;
    color: #333333;
    border : 1px solid #a8a8a8 ;
    border-collapse: collapse;
    text-align: justify
  }

  .Table_description__1gG26 {
    white-space: pre-line;
  }

button {
  
    align-items: center;
    font-size: 16px;    
    background-color: A8A8A8;
    border: 0px  #ccc;
    border-radius: 4px;
  
    
    cursor: pointer;
}
  

  
button i {
    
    font-size: 12px;
    color: #0b0000;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    padding: 1px;
    border-radius: 4px;
    width: 10px;
    height: 14px;
   padding: 0;
    
    
    
    
 }
  
button.Table_active__fe7bi i {
    color: #555;
  }
  
button.Table_active__fe7bi i.Table_up__26Aj7 {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  
.TableFooter_tableFooter__3utxh {
    background-color:#EFEFEF;
    padding: 8px 0px;
    width: 100%;
    font-weight: 500;
    text-align: left;
    font-size: 16px;
    color: #333333;

    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  
  .TableFooter_button__u2WMm {
    border: none;
    padding: 7px 14px;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 4px;
    margin-left: 4px;
  }
  
  .TableFooter_activeButton__2WDVD {
    color:#333333;
    background: #A8A8A8;
  }
  
  .TableFooter_inactiveButton__3rFI0 {
    color: #2c3e50;
    background: #f9f9f9;
  }
.formSelect, .formInput {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .formSubmit {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .formSubmit:hover {
    background-color: #45a049;
  }
  
  .formDiv {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }

  .formLabel {
      margin-right: 10px;
  }
.Program_container__2xmp4 {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  /* height: 100vh; */
  width: 100vw;
  padding-bottom: 50px;
}

.Program_wrapper__2QhR2 {
  width: 80%;
}
.Program_infolog1--text__3o_se{
  font-family: 'Source Sans Pro';
  color: #200A58;
  flex-direction: 'row';
  background-color: #eee;
  text-align: 'center';
}

.Program_section__sDFzr{
  text-align: center;
}
.Table_table-container__2SmKP {
  width: 100%;
  max-height: calc(100vh - 50px); 
  overflow-y: auto; 
  margin-bottom: 20px; /* Add some margin between the table and the footer */
}

.Table_table__2BUHE {
  border-collapse: collapse;
  border: none;
  table-layout:fixed;  
  width: 100%;
  background-color: #FFFFFF;
  word-wrap:break-word;  
  border : 1px solid;
  border-collapse: collapse;
  overflow-y: scroll;
}

.Table_tableRowHeader__8CzBG {
  background-color: transparent;
  transition: all 0.25s ease;
 
  
}

.Table_tableHeader__17PJ5 {
  background-color:  white;
  padding: 12px;
  font-weight: 600;
  text-align: left;
  font-size: 14px;
  color: #333333;
  font-family: 'Source Sans Pro', serif;  
  border : 1px solid;  
  border-collapse: collapse;
}

.Table_tableHeader__17PJ5:first-child {
  
}

.Table_tableHeader__17PJ5:last-child {
  
}

.Table_tableRowItems__2um2n {
  cursor: auto;
}

.Table_tableRowItems__2um2n:nth-child(odd) {
  background-color:#E8E8E8;
}

.Table_tableCell__1zFHz {
  padding: 12px;
  font-size: 14px;
  color: #333333;
  font-family: 'Source Sans Pro', serif;
  
  border : 1px solid #a8a8a8;
  border-collapse: collapse;
}
.TableFooter_tableFooter__2APYA {
    background-color:#EFEFEF;
    padding: 8px 0px;
    width: 100%;
    font-weight: 500;
    text-align: left;
    font-size: 16px;
    color: #333333;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .TableFooter_button__jeDqO {
    border: none;
    padding: 7px 14px;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 4px;
    margin-left: 4px;
  }
  
  .TableFooter_activeButton__2rsKd {
    color:#333333;
    background: #A8A8A8;
  }
  
  .TableFooter_inactiveButton__23397 {
    color: #2c3e50;
    background: #f9f9f9;
  }
.Program_container__3wumi {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  height: 100vh; 
  width: 100vw;
}

.Program_wrapper__3umKP {
  width: 80%;
}
.Program_infolog1--text__2OH77{
  font-family: 'Source Sans Pro';
  color: #200A58;
  flex-direction: 'row';
  background-color: #eee;
  text-align: 'center';
}

.Program_section__niQvf{
  text-align: center;
}
.Table_table__b7Izw {
  border-collapse: collapse;  
  
  width: 100%;
  table-layout:fixed;
  word-wrap:break-word;
  
  background-color: #FFFFFF;
  border : 1px solid;
  border-collapse: collapse;  
  overflow-y: scroll; 


}

.Table_tableRowHeader__1j5Hb {
  background-color: transparent;
  transition: all 0.25s ease;
   
  
}

.Table_tableHeader__3zSX6 {
  background-color:  white;
  padding: 12px;
  font-weight: 600;
  text-align: justify;
  font-size: 14px;
  color: #333333;
  border : 1px solid;
  border-collapse: collapse;
  white-space: nowrap;
}

.Table_tableHeader__3zSX6:first-child {
  
}

.Table_tableHeader__3zSX6:last-child {
  
}

.Table_tableRowItems__23uYl {
  cursor: auto;
}

.Table_tableRowItems__23uYl:nth-child(odd) {
  background-color:#E8E8E8;
}

.Table_tableCell__1iSBa {
  padding: 12px;
  font-size: 14px;
  color: #333333;
  border : 1px solid #a8a8a8 ;
  border-collapse: collapse;
  text-align: justify
}

.Table_description__2WNJr {
  white-space: pre-line;
}

button {

  align-items: center;
  font-size: 16px;    
  background-color: A8A8A8;
  border: 0px  #ccc;
  border-radius: 4px;

  
  cursor: pointer;
}



button i {
  
  font-size: 12px;
  color: #0b0000;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  padding: 1px;
  border-radius: 4px;
  width: 10px;
  height: 14px;
 padding: 0;
  
  
  
  
}

button.Table_active__lwwNA i {
  color: #555;
}

button.Table_active__lwwNA i.Table_up__3Lara {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}


.TableFooter_tableFooter__3rJjh {
  background-color:#EFEFEF;
  padding: 8px 0px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  color: #333333;

  display: flex;
  align-items: center;
  justify-content: center;
 
}

.TableFooter_button__1u0wn {
  border: none;
  padding: 7px 14px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}

.TableFooter_activeButton__117XB {
  color:#333333;
  background: #A8A8A8;
}

.TableFooter_inactiveButton__1-y7u {
  color: #2c3e50;
  background: #f9f9f9;
}
* {
  box-sizing: border-box;
  /* outline: 1px dashed #f00; */
}

body {
  margin: 5em;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
  height: 95vh;
}

