@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');


.home__hero-section {
    color: #fff(0, 0, 0);
    padding: 10px 0;
    height: fit-content;
    padding-bottom: 50px;
  }
  
  .home__hero-row {
    align-items: center;
  }
  
  .row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
  }
  
  .col {
    margin-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
    font-family: 'Source Sans Pro', serif;
    
  }
  
  .home__hero-text-wrapper {
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
    
  }
  .paragraph{
    text-align: justify;
  }
  .top-line {
    color: #f00946;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  
  .heading {
    margin-bottom: 24px;
    line-height: 1.4em;
    font-weight: 400;
    color: #200A58;
    font-size: 38px;
    font-family: 'Source Sans Pro', serif;

    
  }
  
  .dark {
    color: #200A58;
    font-family: 'Source Sans Pro', serif;
    
  }
  
  .darkBg {
   
    background-color: #1c1c1c;
    color: #fff;
  }
  
  .darkBg * {
    color: #fff;
  }
  
  .home__hero-subtitle {
    max-width: 540px;
    margin-bottom: 10px;
    font-size: 17px;
    text-align: justify;
    line-height: 24px;
    color: white;

  }

  .home__hero-subtitle2 {
    max-width: 540px;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: justify;
    line-height: 24px;
    padding-left: 50px;
    
  }
  
  .home__hero-img-wrapper {   
 
    display: flex;
    justify-content: center;
  }
  
  .home__hero-img {   
   
    width: 50%;
   
    margin-top: 0;
    margin-right: 0;
     
    
    padding-right: 0;
    
    margin: 0.5em;
    padding: 0.5em;
   
  }
  .divider{
    border:1px solid gray;
    background-image: linear-gradient(#E31937,#5236AB);
    
    width:6px;
    
    margin-top: 12px;
    margin-bottom : 12px;
 
  }
  .divider2{
    text-align:center;
    margin-top:auto;
    margin-bottom:auto;
  }

 

  .right{
    height:50px;
    width: 50px;
    
  }
  .text {
    font-family: 'Source Sans Pro';
    font-size: large;
    
    
  }
  .corner{
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  img {
    border: 0;
    max-width: 100%;
    vertical-align:middle;
    
    
    display: inline-block;
    
  }
  
  @media screen and (max-width: 991px) {
    .container {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .home__hero-text-wrapper {
      padding-bottom: 65px;
    }
  
    .col {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .links{
    color: #270a9d;
  }


  .material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 100,
    'GRAD' 200,
    'opsz' 48;
    align-self: center;
    
  }
 
