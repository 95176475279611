* {
  box-sizing: border-box;
  /* outline: 1px dashed #f00; */
}

body {
  margin: 5em;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
  height: 95vh;
}
