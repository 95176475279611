@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

.card {
  width: 33%;
  display: flex;
  margin: 3px;
  max-height: 25%;
  overflow: scroll;
  font-size: 12px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid gray;
  border-top: 7px solid gray;
}

.title {
  font-family: Source Sans Pro;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #200a58;
  line-height: 1.4em;
}

.text {
  font-family: Source Sans Pro;
  padding: 5px 10px 10px;
  font-size: 15px;
  font-weight: 300;
  color: #1c1c1c;
  line-height: 1.5em;
  vertical-align: middle;
  flex: 1;
}

.date {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 400;
  color: #555;
  display: flex;
  align-items: center;
  padding: 10px;
}

.time {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 400;
  color: #555;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-left: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.join-button {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #200a58;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.join-button:hover {
  background-color: #fff;
  color: #200a58;
  border: 1px solid #200a58;
}

.read-more {
    color: blue;
    text-decoration: underline;
  }
  
