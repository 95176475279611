.titleCsr{
    text-align: center;
    align-items: center;
    font-size:40px; 
   
    
    letter-spacing:1px;
    text-transform: uppercase;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 50px;
    font-family: 'Source Sans Pro';
    color: #200A58;
    flex-direction: 'row';
    background-color: "#eee";
    text-align: 'center';
}

.titleCsr::after, .titleCsr::before{
    content: " ";
    display: block;
    height: 5px;
}

