.table {
  border-collapse: collapse;  
  
  width: 100%;
  table-layout:fixed;
  word-wrap:break-word;
  
  background-color: #FFFFFF;
  border : 1px solid;
  border-collapse: collapse;  
  overflow-y: scroll; 


}

.tableRowHeader {
  background-color: transparent;
  transition: all 0.25s ease;
   
  
}

.tableHeader {
  background-color:  white;
  padding: 12px;
  font-weight: 600;
  text-align: justify;
  font-size: 14px;
  color: #333333;
  border : 1px solid;
  border-collapse: collapse;
  white-space: nowrap;
}

.tableHeader:first-child {
  
}

.tableHeader:last-child {
  
}

.tableRowItems {
  cursor: auto;
}

.tableRowItems:nth-child(odd) {
  background-color:#E8E8E8;
}

.tableCell {
  padding: 12px;
  font-size: 14px;
  color: #333333;
  border : 1px solid #a8a8a8 ;
  border-collapse: collapse;
  text-align: justify
}

.description {
  white-space: pre-line;
}

button {

  align-items: center;
  font-size: 16px;    
  background-color: A8A8A8;
  border: 0px  #ccc;
  border-radius: 4px;

  
  cursor: pointer;
}



button i {
  
  font-size: 12px;
  color: #0b0000;
  transition: transform 0.2s ease-in-out;
  padding: 1px;
  border-radius: 4px;
  width: 10px;
  height: 14px;
 padding: 0;
  
  
  
  
}

button.active i {
  color: #555;
}

button.active i.up {
  transform: rotate(180deg);
}

