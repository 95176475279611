.formSelect, .formInput {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .formSubmit {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .formSubmit:hover {
    background-color: #45a049;
  }
  
  .formDiv {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }

  .formLabel {
      margin-right: 10px;
  }